import type { LinkProps } from "@remix-run/react";
import { Link, useNavigate } from "@remix-run/react";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "~/utils";
import { ChevronLeft } from "../icons";
import { LoadingSpinner } from "./loading-spinner";

const buttonVariants = cva(
  "cursor-pointer inline-flex items-center justify-center rounded-xl ring-offset-white transition-all focus-visible:outline-none focus:outline-none disabled:cursor-not-allowed disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary:
          "bg-brand text-white hover:bg-primary-500 active:accent disabled:opacity-50 disabled:bg-gray-700",
        secondary:
          "bg-white text-gray-700 border border-gray-300 hover:text-gray-1000 hover:border-gray-500 active:border-gray-500 disabled:border-gray-500 disabled:text-gray-500",
        tertiary:
          "text-primary-700 hover:text-gray-600 hover:underline active:text-gray-1000 active:underline disabled:text-gray-500",
        "primary-sky-blue":
          "bg-primary-200 hover:bg-primary-500 text-brand hover:text-white active:text-gray-200 disabled:text-gray-500",
      },
      size: {
        default: "h-9 px-4 py-5 text-sm",
        lg: "h-12 px-4 py-5 text-lg",
        icon: "h-11 w-11 p-[14px] text-sm",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

export type CustomLinkProps = VariantProps<typeof buttonVariants> &
  LinkProps & {
    isLoading?: boolean;
  };
type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    icon?: React.ReactNode;
    isLoading?: boolean;
  };
type CustomDownloadProps = VariantProps<typeof buttonVariants> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonWithIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: React.ReactNode;
};
const ButtonWithIcon = React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(
  ({ icon, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={`flex h-9 w-9 items-center justify-center rounded-full border border-primary-200 bg-primary-100 text-primary-500`}
        {...props}
      >
        <p>{icon}</p>
      </button>
    );
  },
);

ButtonWithIcon.displayName = "ButtonWithIcon";
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, variant, size, icon, isLoading, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      >
        {isLoading ? <LoadingSpinner /> : children}
        {icon ? <span className="mr-2">{icon}</span> : null}
      </button>
    );
  },
);

Button.displayName = "Button";

const LinkButton = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(
  ({ className, children, variant, size, isLoading, ...props }, ref) => {
    return (
      <Link
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
        ref={ref}
      >
        {isLoading ? <LoadingSpinner /> : children}
      </Link>
    );
  },
);

LinkButton.displayName = "LinkButton";

type CardButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
const CardButton = React.forwardRef<HTMLButtonElement, CardButtonProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className="cursor:pointer flex h-[96px] items-center rounded-xl border border-gray-300 bg-white px-3"
        type="submit"
        {...props}
      >
        {children}
      </button>
    );
  },
);

CardButton.displayName = "CardButton";

const DownloadButton = React.forwardRef<HTMLAnchorElement, CustomDownloadProps>(
  ({ className, children, variant, size, ...props }, ref) => {
    return (
      <a
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);

DownloadButton.displayName = "DownloadButton";

type BackButtonProps = {
  text?: string;
  navOverride?: string;
};

const BackButton = React.forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ text = "Back", navOverride, ...rest }, ref) => {
    const navigate = useNavigate();
    const goBack = () => navigate(navOverride || "..");
    return (
      <button
        onClick={goBack}
        ref={ref}
        className="flex flex-row items-center text-gray-700"
      >
        <div
          className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-gray-100 shadow-lg"
          {...rest}
        >
          <ChevronLeft className="h-5 w-5 -translate-x-[1px] hover:cursor-pointer" />
        </div>
        {text && <p className="ml-5 text-sm">{text}</p>}
      </button>
    );
  },
);

BackButton.displayName = "BackButton";

export {
  BackButton,
  Button,
  ButtonWithIcon,
  DownloadButton,
  LinkButton,
  CardButton,
  buttonVariants,
};
