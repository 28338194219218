import { useSignIn } from "@clerk/remix";
import { getAuth } from "@clerk/remix/ssr.server";
import { parseWithZod } from "@conform-to/zod";
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import {
  Link,
  useFetcher,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { CheckCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { redirect } from "remix-typedjson";
import { Card } from "~/components/ui/card";
import { Text } from "~/components/ui/text";

import {
  associateExistingWithClerk,
  UserNotFound,
} from "~/services/users/onboarding.server";
import { getHostname, safeRoutes } from "~/utils/routes";
import { SignInError, SignInForm, signInSchema } from "./components/signInForm";
import { businessGuard } from "../business+/business-guard.server";
import { Title } from "~/components/ui/title";
import { getPublicEnvs } from "~/utils/env.server";

export const loader = async (args: LoaderFunctionArgs) => {
  const { sessionId } = await getAuth(args);
  const ENV = getPublicEnvs();

  if (sessionId) {
    const { currentBusiness } = await businessGuard(args);
    throw redirect(
      safeRoutes.business.home(currentBusiness?.slug ?? "onboarding"),
    );
  }

  return {
    host: getHostname(args.request),
    googleClientId: ENV.GOOGLE_CLIENT_ID,
  };
};

export async function action({ request }: ActionFunctionArgs) {
  let submission;

  try {
    const formData = await request.formData();
    submission = parseWithZod(formData, { schema: signInSchema });

    if (submission.status !== "success") {
      throw new Error("Form validation failed.");
    }

    // Check if exists in our system and associates with Clerk if missing.
    const user = await associateExistingWithClerk(submission.value.email);

    return json({
      success: true,
      user,
      email: submission.value.email,
    });
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);

    let error: any = {};

    if (err instanceof UserNotFound) {
      error.email = ["This email address is not registered."];
    }

    return json({
      success: false,
      user: null,
      email: null,
      ...submission?.reply(),
      error,
    });
  }
}

export default function LoginPage() {
  const fetcher = useFetcher<typeof action>({ key: "sign-in" });
  const { host, googleClientId } = useLoaderData<typeof loader>();
  const { signIn } = useSignIn();
  const [emailSent, setEmailSent] = useState(false);
  const [searchParams] = useSearchParams();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>, data: any) {
    e.preventDefault();
    if (data.submission?.status === "success") {
      try {
        // Since we are using fetcher.Form, we need to submit the form manually
        // after pre-validation with Clerk.
        fetcher.submit(data.formData, { method: "post" });
      } catch (err: any) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    /**
     * If everything went well, we try to send the email verification to
     * the specified email.
     */
    const sendEmailLink = async () => {
      if (fetcher.data?.success) {
        if (emailSent) return;

        try {
          // Start the sign-in process using magic link strategy
          const redirectUrl = new URL(safeRoutes.auth.verifyEmailLink, host);

          // Check if a custom redirect url is provided (post auth deeplinking...)
          const postAuthRedirect = searchParams.get("redirect") || null;
          if (postAuthRedirect) {
            redirectUrl.searchParams.append("redirect", postAuthRedirect);
          }

          await signIn?.create({
            identifier: fetcher.data.email ?? "",
            strategy: "email_link",
            redirectUrl: redirectUrl.toString(),
          });
          setEmailSent(true);
        } catch (err: any) {
          // Forces Clerk errors to map to email field since is the strategy
          // we are using to check valid addresses and create the session
          // with Clerk.
          if (err.clerkError) {
            throw new SignInError("Problem sending login link.", {
              email: err.errors.map((i: any) => i.message),
            });
          }
          console.error(err);
        }
      }
    };
    sendEmailLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data, signIn?.id, emailSent]);

  return (
    <div className="w-full p-3">
      <Title variant="h1" color="brand" className="mt-[16px] text-center">
        Sign in to your account
      </Title>

      <Card className="m-auto mt-12 w-full px-6 py-8 text-black md:w-3/6">
        {emailSent ? (
          <div className="flex flex-col items-center justify-center">
            <CheckCircleIcon className="mb-5 h-10 w-10 text-green-600 animate-in" />
            <Text variant="h2" className="text-center text-xl font-bold">
              Check your Inbox
            </Text>
            <Text variant="p" className="text-base font-medium">
              We’ve just sent a link to login to your email.
            </Text>
            <div
              onClick={() => setEmailSent(false)}
              className="mt-6 text-center text-sm text-gray-700"
            >
              You didn't receive it?{" "}
              <a
                href={safeRoutes.auth.signIn}
                className="underline-accent text-accent underline-offset-2 hover:underline"
              >
                Go back to login.
              </a>
            </div>
          </div>
        ) : (
          <SignInForm googleClientId={googleClientId} onSubmit={handleSubmit} />
        )}

        <span className="text-md mt-4 text-center text-gray-600">
          Don't have an account?{" "}
          <Link className="underline" to={safeRoutes.auth.signUp}>
            Register here
          </Link>
        </span>
      </Card>
      <div className="mt-8 flex justify-center">
        <a
          href="https://www.withhansa.com/termsandconditions"
          target="_blank"
          className="text-sm text-gray-600 underline"
          rel="noreferrer"
        >
          terms & conditions
        </a>
      </div>
    </div>
  );
}
